<template>
  <div>
    <!-- <iframe
      :src="'https://maps.google.com/maps?q=' + message.location.lat + ', ' + message.location.long + '&z=15&output=embed'"
      width="360"
      height="270"
      frameborder="0"
      style="border:0"
    ></iframe> -->
    <img
      :src="
        'https://maps.googleapis.com/maps/api/staticmap?center=' +
          message.location.lat +
          ',' +
          message.location.long +
          '&zoom=15&size=410x310&markers=color:red%7C' +
          message.location.lat +
          ',' +
          message.location.long +
          '&key=' +
          apiKey
      "
      class="qchat-conv__rounded__location"
    />
    <p class="qchat-conv__bubble__message q-mb-1 q-mt-2">
      <span class="q-text-danger">
        <Icons type="la-map-pin q-text-danger" size="18" />
      </span>
      {{ getCompleteLocation(message.location.name, message.location.address) }}
    </p>
  </div>
</template>
<script>
const Icons = () => import("../../Icons/index.vue");

export default {
  components: {
    Icons
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      apiKey: process.env.GOOGLE_API_KEY
    };
  },
  methods: {
    getCompleteLocation(name, addr) {
      if (!name && !addr) return "Location";
      else {
        const data = [name, addr];
        return data.join(", ");
      }
    }
  }
};
</script>
