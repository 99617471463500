var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"qchat-conv__rounded__location",attrs:{"src":'https://maps.googleapis.com/maps/api/staticmap?center=' +
        _vm.message.location.lat +
        ',' +
        _vm.message.location.long +
        '&zoom=15&size=410x310&markers=color:red%7C' +
        _vm.message.location.lat +
        ',' +
        _vm.message.location.long +
        '&key=' +
        _vm.apiKey}}),_c('p',{staticClass:"qchat-conv__bubble__message q-mb-1 q-mt-2"},[_c('span',{staticClass:"q-text-danger"},[_c('Icons',{attrs:{"type":"la-map-pin q-text-danger","size":"18"}})],1),_vm._v(" "+_vm._s(_vm.getCompleteLocation(_vm.message.location.name, _vm.message.location.address))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }